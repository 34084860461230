import Vue from 'vue'
import Vuex from 'vuex'

//1.安装插件
Vue.use(Vuex)

//2.创建对象
export default new Vuex.Store({
  state: {
    access_token: ''
  },
  mutations: {
    setAccess_token(state, n) {
      state.access_token = n
    },
  },
  actions: {

  },
  getters: {
    getAccess_token(state) {
      return state.access_token
    }

  },
  modules: {

  }
})
