import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "@/store/store.js";
import app from "./app.vue";
import Meta from "vue-meta";
import { Popup, List, Icon, Tab, Tabs } from "vant";
import "vant/lib/index.css"; //引入vant所有组件样式
Vue.use(Popup);
Vue.use(List);
Vue.use(Icon);
Vue.use(Meta);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(ElementUI);
new Vue({
  el: "#app",
  router,
  store,
  data: {
    title: "中峪数交",
    keywords: "中峪数交,中峪拍卖交易",
    description:
      "中峪数交于2021年2月正式批复成立的交易市场，公司坐落于环境优化、人文底蕴浓厚的浙江绍兴市柯桥区；企业以产融孵化、促进商品流通内需、帮助企业去库存、刺激市场消费，独创全新批零交割商业架构，服务于全国各中小型实体生产企业",
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "keywords",
          content: this.keywords,
        },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  render: (h) => h(app),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
});
