import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: '',
            component: () => import('@/view/index.vue'),
        },
        {
            path: '/download',
            name: 'download',
            component: () => import('@/view/download.vue'),
        },
        {
            path: '/introduction',
            name: 'introduction',
            component: () => import('@/view/introduction.vue'),
        },
        {
            path: '/business',
            name: 'business',
            component: () => import('@/view/business.vue'),
        },
        {
            path: '/businessDetail',
            name: 'businessDetail',
            component: () => import('@/view/businessDetail.vue'),
        },
        {
            path: '/newsList',
            name: 'newsList',
            component: () => import('@/view/newsList.vue'),
        },
        {
            path: '/newsListDetail',
            name: 'newsListDetail',
            component: () => import('@/view/newsListDetail.vue'),
        },
        {
            path: '/m',
            name: 'm',
            component: () => import('@/view/mindex.vue'),
        },
        {
            path: '/mdownload',
            name: 'mdownload',
            component: () => import('@/view/mdownload.vue'),
        },
        {
            path: '/mintroduction',
            name: 'mintroduction',
            component: () => import('@/view/mintroduction.vue'),
        },
        {
            path: '/mbusiness',
            name: 'mbusiness',
            component: () => import('@/view/mbusiness.vue'),
        },
        {
            path: '/mbusinessDetail',
            name: 'mbusinessDetail',
            component: () => import('@/view/mbusinessDetail.vue'),
        },
        {
            path: '/mnewsList',
            name: 'mnewsList',
            component: () => import('@/view/mnewsList.vue'),
        },
        {
            path: '/mnewsListDetail',
            name: 'mnewsListDetail',
            component: () => import('@/view/mnewsListDetail.vue'),
        },
    ]
})
export default router
